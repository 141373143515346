<template>
    <div class="news-details-warp">
        <h2 class="title" style="text-transform:Capitalize;">
            {{$store.state.lang=='zh'? detailsData.titleLangZh: detailsData.title }}
        </h2>
        <!-- <p class="time" v-if="detailsData.timeConvert">{{detailsData.timeConvert}}</p> -->
        <div class="content" v-html="$store.state.lang=='zh'? detailsData.contentLangZh: detailsData.content"></div>

    </div>
</template>
 
<script>
import {getNewsContent} from "@/apis"
import {timeConvert} from "@/utils"
    export default {
        name: 'vueName',
        data(){
            return {
                msg:'Welcome to your vueName',
                detailsData: {}
            }
        },
        created(){},
        methods: {
            async getNewsContent(){
                const res = await getNewsContent({id: this.$route.query.id})
                
                const item = res.data[res.total - 1]
                
                // item.timeConvert =  timeConvert(item.createTime)
                this.detailsData = item
            }
        },
        mounted(){
            console.log(this.$route.params);
            this.getNewsContent()
        },
    }
</script>
 
<style scoped lang="scss">
    .news-details-warp{
        padding-top: 180px;
        width: 80%;
        margin: 0 auto;
        min-height: calc(100vh - 224px);
        .title{
            text-align: center;
            padding: 30px 0;
        }
        .time{
            text-align: right;
            padding: 10px 20px 30px;
        }
        .content{
            
            line-height: 20px!important;
            span{
                display: inline-block!important;
                line-height: 20px!important;
            }
        }
    }
</style>